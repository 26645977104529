import React, { ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';
import FlexWrap from '../common/FlexWrap';
import ICON_CANCLE from '../../assets/common/cancle.png';

interface IProps {
  onClose: () => void;
}

function NoticeOldVersion({ onClose }: IProps) {
  const accessToken = localStorage.getItem('loginToken');
  const buttonRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, []);

  return (
    <Container>
      <Modal>
        <TitleWrapper>
          <ModalTitle>구버전 서비스 지원 종료 안내</ModalTitle>
          <ImageButton type='button' onClick={onClose}>
            <img src={ICON_CANCLE} alt='닫기' width={24} height={24} />
          </ImageButton>
        </TitleWrapper>
        <Description>
          안녕하세요, KTP입니다.
          <br />
          현재 사용중이신 KTP 환급웹{' '}
          <DescriptionCaution>
            구버전 서비스가 2024년 11월 1일부터 종료
          </DescriptionCaution>
          됩니다.
          <br />
          <br />
          서비스의 유지보수와 신기능 업데이트 적용을 위해 신버전의 사용을
          결정하게 되었습니다.
          <br />
          <DescriptionCaution>
            2024년 11월 1일부터 신버전 이용만 가능하오니 이점 양해 바랍니다.
          </DescriptionCaution>
          <br />
          <br />
          감사합니다.
        </Description>
        <BtnContainer>
          <Button
            ref={buttonRef}
            onClick={onClose}
            colored
            active={true}
            href={`https://ktpmedical.ktaxpay.com/auto-login/${accessToken}`}
          >
            신버전 사용하기
          </Button>
        </BtnContainer>
      </Modal>
    </Container>
  );
}
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Modal = styled(FlexWrap)`
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  width: 580px;
  background-color: white;
  border-radius: 16px;
  min-width: 400px;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;
const ModalTitle = styled.h3`
  color: #030303;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
`;
const ImageButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
`;
const Description = styled.p`
  margin: 42px 0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.32px;
  word-break: keep-all;
  color: #3a3b3e;
`;
const DescriptionCaution = styled.strong`
  color: #ed0828;
  font-weight: 600;
`;
const BtnContainer = styled(FlexWrap)`
  width: 100%;
`;
const Button = styled.a<{ colored?: boolean; active: boolean }>`
  height: 52px;
  flex: 1;
  margin: auto;
  padding: 14px 20px;
  max-width: 340px;
  background-color: ${(props) =>
    props.active ? (props.colored ? '#246cf6' : '#E5E6E8') : '#D3E2FD'};
  color: ${(props) =>
    props.active ? (props.colored ? '#fff' : '#3A3B3E') : '#fff'};
  border: 1px solid #246cf6;
  border: 1px solid ${(props) => (props.active ? '#E5E6E8' : '#D3E2FD')};
  border-radius: 8px;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  :hover {
    opacity: 0.85;
  }
`;

export default NoticeOldVersion;
