import React from 'react';
import styled from 'styled-components';
import NoticeContent from '@/components/notice/NoticeContent';
import Footer from '@/components/common/Footer';
import { Outlet, useLocation } from 'react-router-dom';
import RenewalNotification from '@/components/common/RenewalNotification';
import WarningExpired from '@/components/common/WarningExpired';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';

function Notice() {
  const { isExpiryWarning, globalStatus } = useGetGlobalStatus();
  const { state } = useLocation();

  return (
    <Container>
      <RenewalNotification />
      {state ? <Outlet /> : <NoticeContent />}
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  height: 100vh;
  flex: 1;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.blueColors.scale3};
`;

export default Notice;
